export default {
  heading: 'Burford-Rustic, serif',
  body: 'Hahmlet, serif',
  monospace: 'Menlo, monospace',
  display: 'Hahmlet, serif',
  googleFonts: ['Hahmlet:100,200,300,400,500,600,700,800,900'],
  customFamilies: ['Burford-Rustic'],
  customUrls: [
    'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  ],
}
