export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {},

  footer: {
    backgroundColor: 'black',
    maxWidth: 'unset',
    '.column': {
      display: 'flex',
      flexDirection: ['column', '', 'row'],
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',

      '> *': {
        // flex: '1',
        width: ['', '', '33.3%'],
        padding: ['0.5rem', '', '2rem'],
      },
      '.image': {
        maxWidth: '150px',
        padding: '0rem',
        filter: 'brightness(0) invert(1)',
      },
      '.socialContainer': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        alignItems: 'center',

        '::before': {
          variant: 'customVariants.title',
          content: "'Be Social'",
          fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
          width: '100%',
          textAlign: 'center',
          marginBottom: '1rem',
        },
      },
    },
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['3rem', '', '4rem'],
    fontWeight: 'normal',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '1.5rem',
    color: 'white',
  },
  subtitle: {
    color: '#dfb280',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '', '2rem'],
    marginBottom: '1.5rem',
    '::before': {
      content: "''",
      backgroundColor: 'white',
      width: '10%',
      height: '3px',
    },
    '::after': {
      content: "''",
      backgroundColor: 'white',
      width: '10%',
      height: '3px',
    },
  },
  text: {
    textAlign: 'left',
    lineHeight: '1.75',

    p: {
      lineHeight: '1.75',

      textAlign: 'left',
      fontSize: 'inherit',
    },
  },

  sideBySide1: {
    padding: '1rem',
    '.lazyload-wrapper': {
      maxHeight: '75vh',
    },
    '.content': {
      border: 'solid 2px',
      // backgroundColor: '#5b6164',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem 1rem'],
    },
    '.title': {
      variant: 'customVariants.title',
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text',
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.section': {
      height: 'fit-content',
      margin: 'auto auto 4rem',
      // backgroundColor: 'black',
      '> div': {
        width: '100%',
      },
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['3rem', '', '5rem'],
      border: 'none',
      margin: '0rem',
      padding: '0rem',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',

      width: '100%',
    },
    '.text': {
      variant: 'customVariants.text',
      // fontSize: ['1.25rem', '', '1.5rem'],
    },
  },

  homepageHeroShout: {},

  homepageShout: {
    backgroundColor: 'background',
    '.title': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black'],
      backgroundColor: 'secondary',
      fontFamily: 'body',
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.76rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', 'solid 9px'],
      borderColor: ['', '', 'black'],
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['black', '', '', '#5f4739'],
    },
    '.date': {
      backgroundColor: '#202020',
      border: ['', '', '', '#ffffff solid 9px'],
      borderColor: ['', '', '', 'black'],
      fontSize: '0.8rem',
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black'],
      order: ['', '', '4'],
    },
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    margin: '0rem 0rem',
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background',
    },
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide1',
    '.content': {
      alignItems: 'flex-start',
    },
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1',
    '.content': {
      alignItems: 'flex-start',
    },
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1',
    '.content': {
      alignItems: 'flex-start',
    },
  },

  homepageContactUs: {
    variant: 'customVariants.sideBySide1',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    padding: ['3rem 1rem', '', '8rem 1rem'],
    '.title': {
      variant: 'customVariants.title',
      order: '1',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      order: '2',
    },
    '.text': {
      order: '3',
    },
    a: {
      order: '5',
    },
  },

  // ? ========================
  // ? =====  Venue page  =====
  // ? ========================

  venueRestaurant: {
    variant: 'customVariants.sideBySide1',
  },
  venueOutdoor: {
    variant: 'customVariants.sideBySide1',
  },
  venueBar: {
    variant: 'customVariants.sideBySide1',
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary',
    },
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem',
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2',
      },
    },
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px',
        filter: 'brightness(0) invert(1)',
      },
    },

    '.eventItemImage': {
      minHeight: 'unset',
    },
    '.eventDays, .eventDate': {
      color: 'black',
    },
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: '#562316',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white',
      },
    },
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem',
    },
    h3: {
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      variant: 'customVariants.title',
      textAlign: 'center',
      fontSize: ['2rem', '', '2.5rem'],
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem',
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem'],
      },
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid',
    },
  },
}
